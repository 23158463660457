import i18next from 'i18next';

export const $locale = {
	get: () => i18next.language,
	set: (_host, value) => i18next.changeLanguage(value),
	connect: (_host, _key, invalidate) => i18next.on('languageChanged', invalidate),
	observe: (_host, value) => document.documentElement.setAttribute('lang', value),
};
export const $t = {
	get: () => i18next.t.bind(i18next),
	connect: (_host, _key, invalidate) => i18next.on('languageChanged', invalidate),
};
