import { define, html, dispatch } from 'hybrids';
import { randomString } from 'src/utils';
import { core, slider } from 'src/styles';
import { CurveFunction } from './curve-function';

export const GtRangeInput = {
	inputId: () => `id-range-${randomString()}`,
	ariaLabel: '',
	label: '',
	value: 0,
	easing: 2.75,
	step: 15,
	min: -480,
	max: 480,
	valueOf: ({ easing, step, max }) => CurveFunction({ easing, step, range: max }),
	innerValueOf,
	innerValue: {
		get: ({ innerValueOf, value }) => innerValueOf(value),
		set: (_host, value) => value,
	},
	modifier: '',
	render: ({ inputId, ariaLabel, min, max, step, innerValue, modifier, value }) => html`
		${ariaLabel && html`<label for="${inputId}" class="is-sr-only">${ariaLabel}</label>`}
		<input
			id="${inputId}"
			class="slider ${modifier}"
			type="range"
			min=${min}
			max=${max}
			step=${step}
			value=${innerValue}
			aria-valuemin=${min}
			aria-valuemax=${max}
			aria-valuetext=${value}
			oninput="${updateValue('input')}"
			onchange="${updateValue('change')}">
	`.style(core, slider),
};
define({ GtRangeInput });

function updateValue(eventName) {
	return (host, event) => {
		event.stopPropagation();
		host.value = host.valueOf(event.target.value);
		dispatch(host, eventName);
	};
}

function innerValueOf({ valueOf }) {
	return number => {
		const delta = 0 <= number ? 1 : -1;
		let rangeValue = 0;
		while(Math.abs(valueOf(rangeValue)) < Math.abs(number)) {
			rangeValue += delta;
		}
		return rangeValue;
	};
}
