import { html } from 'hybrids';
import { core, box, section } from 'src/styles';
import { isNumber } from 'src/utils';
import { AppComponent } from 'src/the-app';
import { setWorkingFromMs, setWorkingUntilMs } from 'src/store';

const own = /* css */`
	:host * {
		box-sizing: border-box;
	}
`;

export const TheWorkingHours = {
	...AppComponent,
	active: false,
	hoursContext: ({ $app: { workingFromMs, workingUntilMs } }) => {
		const from = new Date(workingFromMs);
		const until = new Date(workingUntilMs);
		if (isNumber(workingFromMs) && isNumber(workingUntilMs)) { return { from, until }; }
		if (isNumber(workingFromMs)) { return { context: 'from', from }; }
		if (isNumber(workingUntilMs)) { return { context: 'until', until }; }
		return { context: 'blank' };
	},
	render: ({ $t, $locale, $app: { workingFromMs, workingUntilMs }, active, hoursContext }) => html`
		<elix-expandable-section class="is-block" opened=${active} onopened-changed="${updateActive}">
			<label slot="header" class="label has-box-padding-left">${$t('label.workingHours', hoursContext)}</label>

			<div class="box is-shadowless ${active ? '' : 'is-invisible'}">
				<label class="label" aria-hidden="true">${$t('label.workingHoursFrom')}</label>
				<gt-time-input
					ariaLabel=${$t('label.workingHoursFrom')}
					modifier="is-primary"
					locale=${$locale}
					value=${workingFromMs}
					oninput="${({ $app }, { target }) => setWorkingFromMs($app, target.value)}"
				></gt-time-input>

				<label class="label" aria-hidden="true">${$t('label.workingHoursUntil')}</label>
				<gt-time-input
					ariaLabel=${$t('label.workingHoursUntil')}
					modifier="is-primary"
					value=${workingUntilMs}
					locale=${$locale}
					oninput="${({ $app }, { target }) => setWorkingUntilMs($app, target.value)}"
				></gt-time-input>
			</div>
		</elix-expandable-section>
	`.style(core, box, section, own),
};

function updateActive(host, { detail }) {
	host.active = detail.opened;
}
