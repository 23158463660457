import { html } from 'hybrids';
import { core, button, buttons, title, tools } from 'src/styles';
import { AppComponent } from 'src/the-app';
import { setAdjustment, addOvertimeMin } from 'src/store';

const own = /* css */`
	:host {
		display: grid;
		grid-gap: 1rem;
		height: 100%;
		grid-template-rows: 1fr auto auto auto;
		align-items: center;
	}
`;

const _setAdjustment = (host, event) => { setAdjustment(host.$app, event.target.value); };
const _adjustOvertime = host => { addOvertimeMin(host.$app); };
const _adjustOvertimeDown = host => { addOvertimeMin(host.$app, -15); };
const _adjustOvertimeUp = host => { addOvertimeMin(host.$app, 15); };

export const TheOvertime = {
	...AppComponent,
	render: ({ $t, $app: { overtimeMin, adjustment } }) => html`
			<h2 class="title is-family-secondary is-2 has-text-centered">${overtimeFeedback({ $t, overtimeMin })}</h2>
			<label class="label is-family-secondary has-text-centered is-size-4">
				${adjustmentFeedback({ $t, adjustment })}
			</label>
			<gt-range-input
				ariaLabel=${$t('label.addOvertime')}
				modifier="is-fullwidth is-large"
				value=${adjustment}
				oninput="${_setAdjustment}"
			></gt-range-input>
			<div class="buttons">
				<button class="button is-medium has-text-weight-bold" onclick="${_adjustOvertimeDown}">- 15</button>
				<button class="button is-primary is-medium has-text-weight-bold" onclick="${_adjustOvertime}">${$t('action.register')}</button>
				<button class="button is-medium has-text-weight-bold" onclick="${_adjustOvertimeUp}">+ 15</button>
			</div>
		`.style(core, button, buttons, title, tools, own),
};

function overtimeFeedback({ $t, overtimeMin }) {
	if (overtimeMin === 0) { return $t('feedback.overtimeZero'); }
	return 0 < overtimeMin ?
		$t('feedback.overtimeMin', [overtimeMin]):
		$t('feedback.undertimeMin', [-overtimeMin]);
}

function adjustmentFeedback({ $t, adjustment }) {
	return 0 < adjustment ?
		$t('feedback.registerOvertimeMin', [adjustment]):
		$t('feedback.registerEarlyLeave', [-adjustment]);
}
