import { pipe } from 'ramda';

const { abs, pow, round } = Math;

export function CurveFunction({ easing = 2, step = 1, range = 100 } = {}) {
	const curve = x => {
		const result = pow(abs(x) / range, easing) * range;
		return x > 0 ? result : -result;
	};
	const snapToStep = x => round(x / step) * step;
	return pipe(curve, snapToStep);
}
