import { property } from 'hybrids';
import { isNumber } from 'src/utils';
import { guessOvertime } from './guess-overtime';
import { unless } from 'ramda';

export const Store = {
	/** PRIVATE */
	_get: () => localStorage.getItem.bind(localStorage),
	_set: () => localStorage.setItem.bind(localStorage),
	/** STATE */
	workingFromMs: storedNumber({ key: 'workingFromMs' }),
	workingUntilMs: storedNumber({ key: 'workingUntilMs' }),
	overtimeMin: storedNumber({ key: 'overtimeMin', defaultValue: 0 }),
	timeMs: tickingTime({ everySec: 5, updates: 'timeMs' }),
	adjustment: {
		value: 0,
		connect: guessAdjustment,
	},
};

export function setAdjustment(store, value) {
	store.adjustment = value;
}

export function guessAdjustment(store) {
	const { workingFromMs, workingUntilMs, timeMs } = store;
	store.adjustment = guessOvertime({  workingFromMs, workingUntilMs, timeMs, roundTo: 15 });
}

export function clearOvertime(store) {
	store.overtimeMin = 0;
}

export function addOvertimeMin(store, value = store.adjustment) {
	store.overtimeMin += value;
	store.adjustment = 0;
}

export function setWorkingFromMs(store, value) {
	if (!isNumber(value)) { return; }
	store.workingFromMs = value;
	guessAdjustment(store);
}

export function setWorkingUntilMs(store, value) {
	if (!isNumber(value)) { return; }
	store.workingUntilMs = value;
	guessAdjustment(store);
}

function tickingTime({ everySec, updates }) {
	const nowMs = () => new Date().getTime();
	return {
		...property(nowMs()),
		connect: host => {
			const id = setInterval(() => { host[updates] = nowMs(); }, everySec * 1000);
			return () => clearInterval(id);
		},
	};
}

function storedNumber({ key, defaultValue = null }) {
	const orFallback = unless(isNumber, () => defaultValue);
	return {
		get: ({ _get }) => orFallback(parseInt(_get(key))),
		set: ({ _set }, value) => { _set(key, value); return value; },
	};
}
