import { html, define } from 'hybrids';
import { randomString } from 'src/utils';
import { core, navbar } from '../styles';

export function toggleMenu(host) {
	host.menuActive = !host.menuActive;
}

export const BNavbar = {
	ariaLabel: 'main navigation',
	burgerAriaLabel: 'menu',
	menuActive: false,
	menuId: () => `id-menu-${randomString()}`,
	render: ({
		ariaLabel,
		burgerAriaLabel,
		menuActive,
		menuId,
		onToggleMenu = toggleMenu,
	}) => html`
		<nav class="navbar" role="navigation" aria-label="${ariaLabel}">
			<div class="navbar-brand">
				<slot name="brand"></slot>

				<button
					class="navbar-burger burger ${menuActive ? 'is-active' : ''}"
					aria-label="${burgerAriaLabel}"
					aria-expanded="${menuActive ? 'true' : 'false'}"
					data-target="${menuId}"
					onclick="${onToggleMenu}"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</button>
			</div>

			<div id="${menuId}" class="navbar-menu ${menuActive ? 'is-active' : ''}">
				<div class="navbar-start">
					<slot></slot>
				</div>

				<div class="navbar-end">
					<slot name="end"></slot>
				</div>
			</div>
		</nav>
	`.style(core, navbar),
};

define({ BNavbar });
