import { html } from 'hybrids';
import { AppComponent, toggleLang } from 'src/the-app';
import { clearOvertime } from 'src/store';
import { core, button, image, title } from 'src/styles';
import beachUrl from 'src/assets/beach.svg';

export const TheHeader = {
	...AppComponent,
	toggleLangLabel: ({ $locale }) => $locale === 'en' ? 'Váltás magyarra' : 'Switch to English',
	render: ({ $locale, $t, $app: { overtimeMin }, toggleLangLabel }) => html`
		<b-navbar>
			<b-navbar-item slot="brand" src="${beachUrl}">
				<figure class="image is-32x32">
					<img src="${beachUrl}" alt="">
				</figure>
			</b-navbar-item>
			<b-navbar-item slot="brand">
				<button class="button" aria-label="${toggleLangLabel}" onclick="${toggleLang}">${$locale}</button>
			</b-navbar-item>
			<b-navbar-item slot="brand">
				<label class="title is-family-secondary is-4">${$t(0 <= overtimeMin ? 'feedback.adviceToGo' : 'feedback.adviceToWork')}</label>
			</b-navbar-item>

			<b-navbar-item slot="end">
				<button class="button" onclick="${({ $app }) => clearOvertime($app)}">${$t('action.clear')}</button>
			</b-navbar-item>
		</b-navbar>
	`.style(core, button, image, title),
};
