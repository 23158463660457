import i18next from 'i18next';
import { complement, match } from 'ramda';
import { format as formatDate } from 'date-fns';
import { enUS as en, huHU as hu } from './translations';

export * from './descriptors';

export async function init({ lng = getLangFrom(window) || 'en' } = {}) {
	i18next.on('languageChanged', updateHistory(window));
	return i18next.init({
		lng,
		resources: {
			en: { translation: en },
			hu: { translation: hu },
		},
		interpolation: { format },
	});


}

function format(value, format) {
	if (value instanceof Date) {
		return formatDate(value, format);
	}
	return value;
}

function getLangFrom({ location: { hash } }) {
	const lang = hashParts(hash).find(match(/lang=/));
	return lang ? lang.split('=')[1] : undefined;
}

function updateHistory({ history, location }) {
	return lang => {
		if (!lang) { return; }
		const props = hashParts(location.hash)
				.filter(complement(match(/lang=/)))
				.concat(`lang=${lang}`);
		history.replaceState(null, null, `#${props.join('&')}`);
	};
}

function hashParts(hash) {
	return hash.replace(/^#/, '')
			.split('&')
			.filter(Boolean);
}
