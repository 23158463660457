import { parent } from 'hybrids';
import { $t, $locale } from './i18n';
import { Store } from './store';

export const TheApp = {
	$locale,
	$t,
	...Store,
};

export function toggleLang({ $app }) {
	$app.$locale = $app.$locale === 'en' ? 'hu' : 'en';
}

export const AppComponent = {
	$app: parent(TheApp),
	$locale: ({ $app }) => $app.$locale,
	$t: ({ $app }) => (...args) => $app.$t(...args),
};
