import { html } from 'hybrids';
import { core, box, container, footer, section, customHelpers } from 'src/styles';
import { AppComponent } from 'src/the-app';
import backgroundUrl from 'src/assets/background.svg';
import { TheHeader } from './the-header';
import { TheOvertime } from './the-overtime';
import { TheWorkingHours } from './the-working-hours';

const own = /* css */`
	:host {
		--background-image: url('${backgroundUrl}');
		display: grid !important;
		height: 100%;
		grid-template-rows: auto 1fr;
	}
	.has-grid {
		display: grid;
		height: 100%;
		max-width: 680px;
		grid-template-rows: auto 1fr auto;
	}
`;

const styles = [core, box, container, footer, section, customHelpers, own];
const components = { TheHeader, TheOvertime, TheWorkingHours };

export const ThePage = {
	...AppComponent,
	docTitle: {
		get: ({ $t }) => $t('title'),
		observe: (_host, value) => { document.title = value; },
	},
	metaDescription: {
		get: ({ $t }) => $t('description'),
		observe: (_host, value) => {
			document.querySelector('[name="description"]')
					.setAttribute('content', value);
		},
	},
	render: ({ $t }) => html`
		<the-header></the-header>
		<div class="container">
			<main class="section has-grid">
				<div class="box is-paddingless"><the-working-hours></the-working-hours></div>
				<div class="box has-background-image"><the-overtime></the-overtime></div>
				<footer class="footer is-size-7 has-text-centered" innerHTML="${$t('html.footer')}"></footer>
			</main>
		</div>
	`.style(...styles).define(components),
};
