import { html, define } from 'hybrids';
import { core, navbar } from '../styles';

export const BNavbarItem = {
	href: '',
	target: '',
	render: ({ href, target }) => html`
		${href ? Anchor({ href, target }) : Div()}
	`.style(core, navbar),
};

function Anchor({ href, target }) {
	return html`
		<a class="navbar-item" href="${href}" target="${target}">
			<slot></slot>
		</a>`;
}

function Div() {
	return html`
		<div class="navbar-item">
			<slot></slot>
		</div>`;
}

define({ BNavbarItem });
