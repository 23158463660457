const footer = `
A <strong>GT-HOME</strong> egy hobbi-alkalmazás a
<a href="https://gitlab.com/riovir/gt-home" target="_blank" rel="noopener noreferrer">GitLabon</a>.
<strong>Azért létezik mert szórakozás volt építeni.</strong> A
<a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">Netlify</a> hosztolja ingyenes lapkent.
A logót <a href="http://www.freepik.com" target="_blank" title="Freepik" rel="noopener noreferrer">Freepik</a> készítette a
<a href="https://www.flaticon.com/" target="_blank" title="Flaticon" rel="noopener noreferrer">www.flaticon.com</a>-ról,
<a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">
CC 3.0 BY</a> licensz alatt.`;

export const huHU = {
	action: {
		clear: 'Túlóra törlése',
		register: 'Mentés',
	},
	description: 'Egy egyszerű túlóra követő Progresszív Web Alkalmazás',
	feedback: {
		adviceToGo: 'Irány haza!',
		adviceToWork: 'Dolgozz!',
		overtimeZero: 'Rendben vagy',
		overtimeMin: '{{0}} perc túlórád van',
		registerOvertimeMin: 'Még {{0}} perc túlóra',
		registerEarlyLeave: '{{0}} perc lecsúsztatás',
		undertimeMin: '{{0}} percet rá kéne húznod',
	},
	html: { footer },
	label: {
		addOvertime: 'Túlóra hozzáadás',
		workingHours: 'Munkaidő {{from, k:mm}} és {{until, k:mm}} közt',
		workingHours_blank: 'Munkaidő...',
		workingHours_from: 'Munkaidő {{from, k:mm}} utántól',
		workingHours_until: 'Munkaidő {{until, k:mm}}-ig',
		workingHoursFrom: 'Ettől',
		workingHoursUntil: 'Eddig',
	},
	title: 'Túlóra követő',
};
