import { define, html, property, dispatch } from 'hybrids';
import { randomString } from 'src/utils';
import { core, inputTextarea } from 'src/styles';

export const GtTimeInput = {
	inputId: () => `id-time-${randomString()}`,
	ariaLabel: '',
	value: property(),
	textValue: ({ value, locale }) => toText(value, locale),
	modifier: '',
	locale: 'en',
	render: ({ ariaLabel, inputId, textValue, modifier }) => html`
		${ariaLabel && html`<label for="${inputId}" class="is-sr-only">${ariaLabel}</label>`}
		<input
			id=${inputId}
			class="input ${modifier}"
			type="time"
			step="900"
			value=${textValue}
			oninput="${updateValue('input')}"
			onchange="${updateValue('change')}">
	`.style(core, inputTextarea),
};
define({ GtTimeInput });

function updateValue(eventName) {
	return (host, event) => {
		event.stopPropagation();
		host.value = toTimeMs(event.target.value);
		dispatch(host, eventName);
	};
}

function toTimeMs(timeText) {
	const [hr, min] = timeText.split(':');
	const result = new Date(0);
	result.setHours(hr);
	result.setMinutes(min);
	return result.getTime();
}

function toText(timeMs, locale) {
	if (typeof timeMs !== 'number') { return ''; }
	const format = { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' };
	const date = new Date(timeMs);
	return date.toLocaleString(locale, format);
}
