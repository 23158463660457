const footer = `
<strong>GT-HOME</strong> is a playground app hosted on
<a href="https://gitlab.com/riovir/gt-home" target="_blank" rel="noopener noreferrer">GitLab</a>.
<strong>It exists, because it was fun to build.</strong> It's hosted as a
<a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">Netlify</a> free page.
The logo is made by
<a href="http://www.freepik.com" target="_blank" title="Freepik" rel="noopener noreferrer">Freepik</a> from
<a href="https://www.flaticon.com/" target="_blank" title="Flaticon" rel="noopener noreferrer">www.flaticon.com</a> and is licensed by
<a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">
CC 3.0 BY</a>.`;

export const enUS = {
	action: {
		clear: 'Clear overtime',
		register: 'Register',
	},
	description: 'A simple overtime tracker Progressive Web App',
	feedback: {
		adviceToGo: 'Go home!',
		adviceToWork: 'Work!',
		overtimeZero: 'You are fine',
		overtimeMin: 'You have {{0}} minutes overtime',
		registerOvertimeMin: 'Add {{0}} minutes overtime',
		registerEarlyLeave: 'Leave {{0}} minutes early',
		undertimeMin: 'You are {{0}} minutes behind',
	},
	html: { footer },
	label: {
		addOvertime: 'Add overtime',
		workingHours: 'Working between {{from, p}} and {{until, p}}',
		workingHours_blank: 'Working hours...',
		workingHours_from: 'Working from {{from, p}}',
		workingHours_until: 'Working until {{until, p}}',
		workingHoursFrom: 'From',
		workingHoursUntil: 'Until',
	},
	title: 'Overtime tracker',
};
