const DAY_IN_MINS = 60 * 24;

export function guessOvertime({ workingFromMs, workingUntilMs, timeMs, roundTo = 1 }) {
	const boundaries = [
		{ time: workingFromMs, invert: true },
		{ time: workingUntilMs, invert: false },
	].filter(isDefinedTime);
	const [bestGuess] = boundaries
			.map(computeProp('guessMin', shortestDistanceFrom(timeMs)))
			.sort(compareProp('guessMin', absDifference))
			.map(maybeInvertGuess)
			.map(prop('guessMin'))
			.map(roundNumber(roundTo));
	return bestGuess || 0;
}

function toMins(time) {
	if (typeof time !== 'number') { throw new Error(`Invalid time in millis: ${time}`); }
	const date = new Date(time);
	return date.getHours() * 60 + date.getMinutes();
}

function maybeInvertGuess({ invert, guessMin, ...rest }) {
	return {
		invert, ...rest,
		guessMin: invert ? -guessMin : guessMin,
	};
}

function absDifference(left, right) {
	return Math.abs(left) - Math.abs(right);
}

function shortestDistanceFrom(fromMs) {
	const fromMins = toMins(fromMs);
	const possibleMins = [fromMins - DAY_IN_MINS, fromMins, fromMins + DAY_IN_MINS];
	return ({ time }) => possibleMins
			.map(possible => possible - toMins(time))
			.sort(absDifference)[0];
}

function roundNumber(to) {
	return number => Math.round((number / to)) * to;
}

function prop(name) {
	return object => object[name];
}

function computeProp(name, fn) {
	return object => ({ ...object, [name]: fn(object) });
}

function compareProp(name, comparator) {
	return (left, right) => comparator(left[name], right[name]);
}

function isDefinedTime({ time }) {
	return typeof time === 'number';
}
