import './styles/main.scss';
import { define } from 'hybrids';
import { installServiceWorker } from './service-worker';
import { init } from './i18n';
import { TheApp } from './the-app';
import { ThePage } from './components';
import './widgets';

const App = define('the-app', TheApp);
const Page = define('the-page', ThePage);

installServiceWorker({
	onUpdated: () => { location.reload(); },
});
init().then(renderApp);

function renderApp() {
	const app = new App();
	app.appendChild(new Page());
	document.body.appendChild(app);
}
